import { StyledImageLogo } from '../TheWestHeader.styled'
import { createHeaderLogo, LogoProps } from './createHeaderLogo'

const logoFile: string = require('./images/masthead-albany-advertiser.png')

const props: LogoProps = {
    alt: 'Albany Advertiser',
    src: logoFile,
    ampInitialSize: {
        width: 618,
        height: 92,
    },
    verticalSpacing: 4,
}

export default createHeaderLogo(StyledImageLogo, props)
